import request from '@/utils/request'
// 帮助类别
export function category(params) {
  return request({
    url: 'web/index/information/getcjwttype.json',
    method: 'get',
    params
  })
}
// 帮助列表
export function list(params) {
  return request({
    url: 'web/index/information/getcjwt.json',
    method: 'get',
    params
  })
}
// 帮助详情
export function noticedel(params) {
  return request({
    url: 'web/home/footContent.json',
    method: 'get',
    params
  })
}
// 帮助详情附件
export function file(params) {
  return request({
    url: 'standalonfile/operate/getlist.json',
    method: 'get',
    params
  })
}